import { Component, OnInit } from '@angular/core';
import { config } from '../config';
import {RouterModule} from '@angular/router';
import { FormsModule } from '@angular/forms';

@Component({
  templateUrl: './footer.component.html',
  styleUrls: ['./footer.component.css'],
  selector: 'footer',
  standalone: true
 })

export class FooterComponent{
public taxonomy = "";
public class = "";
public subclass = "";

ngOnInit(){
    this.taxonomy = config.taxonomy;
    this.class = config.class;
    this.subclass = config.subclass;
  }

  getFullYear() {
      var d = new Date();
      return d.getFullYear();
  }

}
