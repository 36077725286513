import { Component } from '@angular/core';
import {UserService} from '../../services/user.service';
import {User} from '../user/model/user';
import {Login} from '../../services/user.service';
import { Router } from '@angular/router';
import { AdminAlertComponent } from '../adminAlert/admin-alert.component';
import { AdminAlertService } from '../adminAlert/services/admin-alert.service';
import { AlertTypeEnum } from '../adminAlert/types/alertType.enum';
import { config } from '../../config';
import {RouterModule} from '@angular/router';
import { AdminHeaderComponent } from '../adminHeader/admin-header.component';
import { AdminFooterComponent } from '../adminFooter/admin-footer.component';
import { SiloqpayComponent } from '../siloqpay/siloqpay.component';
import { FormsModule } from '@angular/forms';
import {CookieService} from 'ng2-cookies';
import { CommonModule } from '@angular/common';

@Component({
  templateUrl: './adminGoldimembers.component.html',
  styleUrls: ['./adminGoldimembers.component.css'],
  imports: [AdminAlertComponent, FormsModule, RouterModule, AdminFooterComponent, AdminHeaderComponent, SiloqpayComponent, CommonModule],
  standalone: true
})

export class AdminGoldimembersComponent {
  password2= "";
  passwordFail: boolean = false;
  public taxonomy = "";
  public class = "";
  public subclass = "";
  public goldimembersAnnualPrice = "24.99"
  public goldimembersMonthlyPrice = "4.99"
  public goldimembersDailyPrice = ".99"
  public currency = "USD"
  public theUser = "";
  ttl = "30";
  price = "4.99";
  public theGoldiUser = "";
  user = new User("", "", "");
  siloqpayUrl = "";
  callback = "";
  checkedMonthly=true;
  checkedAnnual=false;
  checkedDaily=false;
  public otherOptions = false;
  public gold = "gold";//"Goldimembers™ Monthly Subscription";

  constructor( private alertService: AdminAlertService, public userSVC: UserService, private router: Router, public loginInfo: Login, private _cookieService:CookieService){
  	loginInfo = new Login();
  }

  getCookie(key: string){
    return this._cookieService.get(key);
  }

  ngOnInit() {
    this.userSVC.sessionVerify();
    this.theUser = this.getCookie("email");
    this.theGoldiUser = this.getCookie("gmt");
    if(this.theGoldiUser && this.theGoldiUser !== 'null') {
      this.router.navigate(['/admin/mocks'],  { queryParams: { t : "GOLD", u : "gold.imoqr@siloq.com"}});
    }

    this.taxonomy = config.taxonomy;
    this.class = config.class;
    this.subclass = config.subclass;
    this.goldimembersMonthlyPrice = config.goldimembersMonthlyPrice;
    this.goldimembersAnnualPrice = config.goldimembersAnnualPrice;
    this.goldimembersDailyPrice = config.goldimembersDailyPrice;
    this.siloqpayUrl = config.siloqpayUrl;
    this.callback = config.siloqpayCallback;
    this.currency = config.currency;
	}

   validateEmail(email: any) {
     const re = /^(([^<>()[\]\\.,;:\s@\"]+(\.[^<>()[\]\\.,;:\s@\"]+)*)|(\".+\"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
     return re.test(email);
   }

  showAlert(type: AlertTypeEnum, msg: string) {
      this.alertService.setAlert({
        type,
        text: msg,
      });
    }

//TODO: Avoid this call.
subscribe() {
    this.userSVC.getUserByEmail(this.theUser)
      .subscribe(
        (user: any) => {
          if(user!=undefined){
            this.user = user;
            this.router.navigate(['/admin/siloqpay'],  { queryParams: { a : this.price, c : this.currency, u : user.id, l : this.ttl, p : this.gold, g : this.callback}});
          }
        },
         (err: any) => {
          console.log(err);
        });
  }

subscribeAnnual(){
    this.price = this.goldimembersAnnualPrice;
    //this.gold = "GmZ Goldimembers™ Annual Subscription";
    this.ttl = "365";
    this.checkedMonthly=false;
    this.checkedAnnual=true;
    this.checkedDaily=false;
  }

subscribeMonthly(event: any){
    //event.srcElement.setAttribute('background-color', 'dodgerblue');
    //event.srcElement.setAttribute('color', 'whitesmoke');
    this.checkedMonthly=true;
    this.checkedAnnual=false;
    this.checkedDaily=false;
    this.price = this.goldimembersMonthlyPrice;
    //this.gold = "GmZ Goldimembers™ Monthly Subscription";
    this.ttl = "30";
  }

subscribeDaily(){
    //this.gold = "GmZ Goldimembers™ Daily Recharge";
    this.price = this.goldimembersDailyPrice;
    this.ttl = "1";
    this.checkedMonthly=false;
    this.checkedAnnual=false;
    this.checkedDaily=true;
  }

  addMeToGoldimembers(){
     alert("coming soon");
  }
 }
