<body style="height:100%;width:100%; margin:0px;">
  <div class="doNotPrint Row" style="font-family: 'Segoe UI', Frutiger, 'Frutiger Linotype', 'Dejavu Sans', 'Helvetica Neue', Arial, sans-serif;padding-top:0px;height:42px; border-bottom:1px solid #ffffff00;left:0; top:0; position:fixed;background-color:#f0f0f0;">

    <div style="font-family: 'Segoe UI', Frutiger, 'Frutiger Linotype', 'Dejavu Sans', 'Helvetica Neue', Arial, sans-serif; display:table-cell;font-size:1em;font-weight:600px;height:38px; width:100%; padding:0px 5px 0px 5px; display:table;text-align:left;margin:0 auto;" >
      <div style="vertical-align: middle;font-family:arial,sans-serif; font-size:.75em;font-weight:200;padding:5px 0px 0px 10px;flow:left;display:table-cell;width:30%;">
        <a *ngIf="userSVC.authUser.email" [routerLink]="['/home']" ><img src="assets/admin-{{taxonomy}}.png" align="middle" title="Home" alt="Home"style="cursor:pointer;margin-bottom:8px;width:31px; height:31;" /></a>
        <a style="cursor:pointer;border-width:4px;background-color:whitesmoke;color:#383838;padding:4px;text-decoration:none;margin:0px 0px 0px 0px;" title="New LOQ" (click)="newLoq();" class="siloqinput">&nbsp;+&nbsp;</a>
      </div>

      <div *ngIf="taxonomy===identity"  style="display:table-cell;text-align:center;padding:10px 1% 0px 0px;width:38%;font-size:1em;font-weight:600;margin:0 auto;" >
        <!-- glean -->
        <a *ngIf="userSVC.authUser.email" routerLink="/admin/topic"><img src="assets/isloq.png" align="middle" title="Loq ACTs" alt="Loq ACTs"  style="cursor:pointer;margin-bottom:10px;width:30px; height:30px;" /></a>
        <!-- transform -->
        <a *ngIf="userSVC.authUser.email" routerLink="/admin/exams"><img src="assets/sisloq-logo.png" align="middle" title="Conduct EXAMs" alt="Conduct EXAMs"  style="cursor:pointer;margin-bottom:10px;width:28px; height:28px;" /></a>
        <!-- retrieve -->
        <a *ngIf="userSVC.authUser.email" routerLink="/admin/mocks"><img src="assets/siloq-logo.png" align="middle" title="Practice MOCKs" alt="Practice MOCKs"  style="cursor:pointer;margin-bottom:10px;width:28px; height:28px;" /></a>
      </div>

      <div *ngIf="taxonomy==='iexamr'"  style="display:table-cell;text-align:center;padding-top:10px;width:38%;font-size:1em;font-weight:700px;margin:0 auto;" >
        <!-- exam published - for group of students - specific-time-->
        <a *ngIf="subclass!=='admin' && userSVC.authUser.email" href="/admin/exams?t=Request&u=request.imoqr@siloq.com"><img style="text-decoration:none;width:30px; height:30px;" title="{{taxonomy}} Recent exam requests" alt="{{taxonomy}} Recent exam requests" align="middle" src="assets/iexamr.png"  style="cursor:pointer;margin-bottom:10px;width:30px; height:30px;"/></a>

        <!-- publish exam - teacher -->
        <a *ngIf="subclass==='admin' && userSVC.authUser.email" href="/admin/exams"><img style="text-decoration:none;width:30px; height:30px;" title="{{taxonomy}} Recent exams" alt="{{taxonomy}} Recent exams" align="middle" src="assets/iexamr.png"  style="cursor:pointer;margin-bottom:10px;width:30px; height:30px;"/></a>

        <!-- reexam the published exams - exam -->
        <a *ngIf="subclass==='admin' && userSVC.authUser.email" (click)="reexams();" ><img style="text-decoration:none;width:30px; height:30px;" title="{{taxonomy}} Reexam" alt="{{taxonomy}} Reexam" align="middle" src="assets/reexam.png"  style="cursor:pointer;margin-bottom:10px;width:30px; height:30px;"/></a>

        <!-- result published - of each student who took the exam - for teacher -->
        <a *ngIf="userSVC.authUser.email" (click)="results();"  ><img style="text-decoration:none;width:30px; height:30px;" title="{{taxonomy}} Recent Results" alt="{{taxonomy}} Recent Results" align="middle" src="assets/result.png"  style="cursor:pointer;margin-bottom:10px;width:30px; height:30px;"/></a>
      </div>

      <div style="display:table-cell;width:30%;flow:right;text-align:right;padding-right:20px;">
        <!-- mocks - for individual student/teacher - Goldimembers Only -->
        <a style="font-size:.8em;cursor:pointer;border-width:4px;background-color:whitesmoke;color:#383838;padding:3px 4px 3px 4px;text-decoration:none;margin:0px 0px 0px 0px;" *ngIf="userSVC.authUser.email" (click)="goldimembers();" class="siloqinput" title="GOLDIMOCKS">⭐️</a>
        <a *ngIf="userSVC.authUser !== undefined && userSVC.authUser.email && theGoldiUser && theGoldiUser !== 'null'" style="cursor:pointer;border-width:4px;padding:1px;text-decoration:none;background-color:white;border-color:orange;color:orange;margin:0px 0px 0px 0px;font-weight:600" title="{{userSVC.authUser.email}}" routerLink="/admin/account" class="siloqinput">&nbsp;{{userSVC.authUser.email.toUpperCase().charAt(0)}}&nbsp;</a>
        <a *ngIf="userSVC.authUser !== undefined && userSVC.authUser.email && (!theGoldiUser || theGoldiUser === 'null')" style="cursor:pointer;border-width:4px;padding:2px;text-decoration:none;margin:0px 0px 0px 0px;font-weight:600" title="{{userSVC.authUser.email}}" routerLink="/admin/account" class="siloqinput">&nbsp;{{userSVC.authUser.email.toUpperCase().charAt(0)}}&nbsp;</a>
      </div>
    </div>
  </div>
