import { Component, OnInit } from '@angular/core';
import {UserService} from '../services/user.service';
import { Router } from '@angular/router';
import {CookieService} from 'ng2-cookies';
import {HostListener} from '@angular/core';
import { config } from '../config';
import {RouterModule, RouterOutlet} from '@angular/router';
import { SisHeaderComponent } from '../sisHeader/sis-header.component';
import { HomeFooterComponent } from '../homeFooter/home-footer.component';
import { FormsModule } from '@angular/forms';
import { CommonModule } from '@angular/common';

@Component({
  templateUrl: './header.component.html',
  styleUrls: ['./header.component.css'],
  selector: 'header',
  imports: [CommonModule, RouterModule, RouterOutlet],
  standalone: true
})
export class HeaderComponent implements OnInit {
  public menu = false;
  timeoutId?: number;
  public identity = "";
  public taxonomy = "";
  public class = "";
  public subclass = "";
  public chromeExtension = "";
  public theUser = "";
  public theGoldiUser = "";

  @HostListener('click')
  clickInside() {
    if (this.menu === true) {
      this.menu = true;
    }
  }

  @HostListener('document:click')
  clickout() {
    this.menu = false;
  }

  constructor( public userSVC: UserService, private router: Router, private _cookieService:CookieService){}

  getCookie(key: string){
    return this._cookieService.get(key);
  }

  ngOnInit(){
    this.userSVC.sessionVerify();
    this.theUser = this.getCookie("email");
    this.theGoldiUser = this.getCookie("gmt");
    this.class = config.class;
    this.subclass = config.subclass;
    this.chromeExtension = config.chromeExtension;
    this.identity = config.identity;
    this.taxonomy = config.taxonomy;
    //Do not navigate to topics automatically as the
    //user needs to see the home page
    //this.router.navigate(['/admin/topic']);
  }

  logout(){
    this.router.navigate(['logout']);
  }

  signin(){
    this.router.navigate(['login']);
  }

  toggleMenu(event:any){
    if(this.menu) {
      this.menu = false;
      event.stopPropagation();
      return;
    }
    this.menu = true;
    this.resetTimer();
    event.stopPropagation();
  }

   resetTimer(): void {
      if (this.timeoutId) {
        window.clearTimeout(this.timeoutId);
      }
      this.timeoutId = window.setTimeout(() => {
        this.menu = false;
      }, 3000);
    }

    remocks() {
          this.router.navigate(['/admin/mocks'],  { queryParams: { t : "Remock", u : "mock.imoqr@siloq.com"}});
    }

    reexams() {
          this.router.navigate(['/admin/exams'],  { queryParams: { t : "Reexam", u : "scheduled.imoqr@siloq.com"}});
    }

      results() {
          this.router.navigate(['/admin/exams'],  { queryParams: { t : "Result", u : "result.imoqr@siloq.com"}});
      }

    goldimembers() {
        if(this.theGoldiUser && this.theGoldiUser !== 'null') {
          this.router.navigate(['/admin/mocks'],  { queryParams: { t : "GOLD", u : "gold.imoqr@siloq.com"}});
        } else {
          this.router.navigate(['/admin/goldimembers']);
        }
      }
}

