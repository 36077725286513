import { Injectable } from '@angular/core';
import {
  HttpInterceptor,
  HttpRequest,
  HttpHandler,
  HttpEvent,
} from '@angular/common/http';
import { Observable } from 'rxjs';
import {CookieService} from 'ng2-cookies';

@Injectable()
export class CustomHeaderInterceptor implements HttpInterceptor {
  constructor(private _cookieService:CookieService) { }

  intercept(request: HttpRequest<unknown>, next: HttpHandler): Observable<HttpEvent<unknown>> {
    let gwt = this._cookieService.get("gwt").trim();
    if (gwt) {
      const modifiedRequest = request.clone({
        headers: request.headers.set('gwt', gwt).set('Content-Type', 'text/plain')
      });
      return next.handle(modifiedRequest);
    } else {
       return next.handle(request);
    }
  }
}
