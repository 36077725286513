import { Component, OnInit, TemplateRef, ViewChild } from '@angular/core';
import {TopicService} from '../../services/topic.service';
import {Topic} from '../topic/model/topic';
import {User} from '../user/model/user';
import {Observable, throwError} from 'rxjs';
import {HostListener} from '@angular/core';
import {ActivatedRoute, Router } from '@angular/router';
import {UserService} from '../../services/user.service';
import {Login} from '../../services/user.service';
import {CookieService} from 'ng2-cookies';
import { AdminAlertComponent } from '../adminAlert/admin-alert.component';
import { AdminAlertService } from '../adminAlert/services/admin-alert.service';
import { AlertTypeEnum } from '../adminAlert/types/alertType.enum';
import { ModalService } from '../../modal/services/modal.service';
import { ModalComponent } from '../../modal/modal.component';
import { config } from '../../config';
import {RouterModule} from '@angular/router';
import { DummyHeaderComponent } from '../dummyHeader/dummy-header.component';
import { DummyFooterComponent } from '../dummyFooter/dummy-footer.component';
import { FormsModule } from '@angular/forms';
import { CommonModule } from '@angular/common';

declare function escape(s:string): string;
declare function unescape(s:string): string;

@Component({
  selector: 'app-share',
  templateUrl: './share.component.html',
  styleUrls: ['./share.component.css'],
  imports: [AdminAlertComponent, FormsModule, RouterModule, DummyFooterComponent, DummyHeaderComponent, CommonModule],
  standalone: true
})

export class ShareComponent implements OnInit {
  public model = new Topic('', '', '');
  public user = new User('', '', '');
  public umodel = new Topic('', '', '');
  selectedIndex = 0;
  sortKey = "insertdate";
  selectAll = false;
  public theUser = "";
 public theGoldiUser = "";
  contributors: string[] = [];
  @ViewChild('modalTemplate', {read: TemplateRef}) modalTemplate: TemplateRef<any>;

  constructor( private alertService: AdminAlertService, private modalService: ModalService, private topicService: TopicService, private activatedRoute: ActivatedRoute, public userSVC: UserService, private router: Router, private _cookieService:CookieService) { }

  getCookie(key: string){
    return this._cookieService.get(key);
  }

  ngOnInit() {
    this.theUser = this.getCookie("email");
  this.theGoldiUser = this.getCookie("gmt");
    this.activatedRoute.queryParams.subscribe(params => {
      this.model.hashTag = unescape(params['h']) || "";
    });

    this.getTopic(this.model.hashTag, this.theUser, (t: any)=>{
      t.hashTag = unescape(t.hashTag);
      this.model = t;
        //var myArray = myArray.filter((item) => !toRemove.includes(item));
        this.contributors = this.model.users.filter(x => ["mock@siloq.com", "exam@siloq.com", "press@siloq.com", "tagndoc@tagndoc.com"].indexOf(x) < 0);
      this.userSVC.getUserByEmail(this.theUser)
        .subscribe(
          (user: any) => {
            if (user) {
              this.user = user; //Bind to view
            }
          },
           (err: any) => {
            console.log(err);
          });
    });
  }

  showAlert(type: AlertTypeEnum, msg: string) {
    this.alertService.setAlert({
      type,
      text: msg,
    });
    }

 openModal(modalTemplate: TemplateRef<any>, size: string, title: string, message: string, callback: () => any) {
     this.modalService.open(modalTemplate, { size: size, title: title, message: message })
       .subscribe((action) => { callback(); });
   }

  getTopic(h: string, e: string, callback: (t: any) => any) {
    this.topicService.getTopic(escape(h), e).subscribe(
      (data: any) => {
        callback(data);
      },
       (err: any) => {
        console.log(err);
      });
  }

  addUserToTopic(user: string){
    if(user) {
      var id = this.model.users.indexOf(user);
      if (id >= 0) {
        return;
      }

      this.model.users.push(user);
      this.contributors = this.model.users.filter(x => ["mock@siloq.com", "exam@siloq.com", "press@siloq.com", "tagndoc@tagndoc.com"].indexOf(x) < 0);
      this.model.hashTag = escape(this.model.hashTag);
      let commentOperation:Observable<Topic[]>;
      this.topicService.updateTopic(this.model).subscribe(
        () => {
          this.model.hashTag = unescape(this.model.hashTag);
          this.showAlert(AlertTypeEnum['success'], "The user '" + user  + "' has been added.");
        },
         (err: any) => {
          console.log(err);
        });
    }
  }

  removeUserFromTopic(user: string){
    if(user) {
      var id = this.model.users.indexOf(user);
      this.model.users.splice(id,  1);
      this.contributors = this.model.users.filter(x => ["mock@siloq.com", "exam@siloq.com", "press@siloq.com", "tagndoc@tagndoc.com"].indexOf(x) < 0);
      this.model.hashTag = escape(this.model.hashTag);
      let commentOperation:Observable<Topic[]>;
      this.topicService.updateTopic(this.model).subscribe(
        () => {
          this.model.hashTag = unescape(this.model.hashTag);
          this.showAlert(AlertTypeEnum['success'], "The user '" + user  + "' has been removed.");
        },
         (err: any) => {
          console.log(err);
        });
    }
  }

  docTopic(topic: any) {
    this.router.navigate(['/admin/loq'],  { queryParams: { h : escape(topic.hashTag), i : topic.id}});
  }

  notify() {
    this.openModal(this.modalTemplate, "md", "Members: Share", "Do you want to sent email notification(s) to the contributor(s)?", () => {
        this.model.hashTag = escape(this.model.hashTag);
        this.userSVC.notify(this.model);
        this.model.hashTag = unescape(this.model.hashTag);
        this.showAlert(AlertTypeEnum['success'], "The topic '" + this.model.hashTag + "' has been shared with the contributors!");
      });
  }
}
