import { Component, OnInit, TemplateRef, ViewChild } from '@angular/core';
import {UserService} from '../../services/user.service';
import {Login} from '../../services/user.service';
import {ActivatedRoute, Router } from '@angular/router';
import {CookieService} from 'ng2-cookies';
import {User} from '../user/model/user';
import { AdminAlertComponent } from '../adminAlert/admin-alert.component';
import { AdminAlertService } from '../adminAlert/services/admin-alert.service';
import { AlertTypeEnum } from '../adminAlert/types/alertType.enum';
import { ModalService } from '../../modal/services/modal.service';
import { ModalComponent } from '../../modal/modal.component';
import { config } from '../../config';
import {RouterModule} from '@angular/router';
import { DummyHeaderComponent } from '../dummyHeader/dummy-header.component';
import { DummyFooterComponent } from '../dummyFooter/dummy-footer.component';
import { FormsModule } from '@angular/forms';
import { CommonModule } from '@angular/common';

@Component({
  host: {
    '(document:keydown)': 'keyDown($event)',
  },

  templateUrl: './invite.component.html',
  styleUrls: ['./invite.component.css'],
  imports: [AdminAlertComponent, FormsModule, RouterModule, DummyFooterComponent, DummyHeaderComponent, CommonModule],
  standalone: true
})

export class InviteComponent implements OnInit {
  public theUser = "";
 public theGoldiUser = "";
  femail= "";
  public user = new User('', '', '');
  @ViewChild('modalTemplate', {read: TemplateRef}) modalTemplate: TemplateRef<any>;
   public taxonomy = "";
   public class = "";
   public subclass = "";
  tinUrl = config.slqioUrl;

  constructor( private alertService: AdminAlertService, private modalService: ModalService, private activatedRoute: ActivatedRoute, public userSVC: UserService, private router: Router, private loginInfo: Login, private _cookieService:CookieService){
  }

  ngOnInit() {
    this.theUser = this.getCookie("email");
  this.theGoldiUser = this.getCookie("gmt");
    this.taxonomy = config.taxonomy;
    this.class = config.class;
    this.subclass = config.subclass;
    this.userSVC.getUserByEmail(this.theUser)
      .subscribe(
        (user: any) => {
          if (user) {
            this.user = user; //Bind to view
          }
        },
         (err: any) => {
          console.log(err);
        });
  }

showAlert(type: AlertTypeEnum, msg: string) {
    this.alertService.setAlert({
      type,
      text: msg,
    });
  }

  openModal(modalTemplate: TemplateRef<any>, size: string, title: string, message: string, callback: () => any) {
    this.modalService.open(modalTemplate, { size: size, title: title, message: message })
      .subscribe((action) => { callback(); });
  }

  validateEmail(email: any) {
    const re = /^(([^<>()[\]\\.,;:\s@\"]+(\.[^<>()[\]\\.,;:\s@\"]+)*)|(\".+\"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
    return re.test(email);
  }

  invite() {
    if (this.femail === "")
    {
      this.showAlert(AlertTypeEnum['warning'], "The value can not be empty");
      return;
    } else if (!this.validateEmail(this.femail)) {
      this.showAlert(AlertTypeEnum['warning'], "Email is not valid. Please re-enter and try again!");
      return;
    }

    this.userSVC.invite(this.theUser, this.femail);
    this.showAlert(AlertTypeEnum['warning'], "The invite has been sent to '"+this.femail+"'!");
    this.femail = "";
  }

  remove(femail) {
    this.openModal(this.modalTemplate, "md", "Invite: Remove Friends(s)", "Do you want to remove your friend " + femail + "?", () => {
      this.userSVC.removed(this.theUser, femail);
      this.router.navigate(['/admin/invite']);
    });
  }

  done() {
    this.router.navigate(['/admin/account']);
  }

 public keyDown(event: any) {
    switch (event.keyCode) {
      case 13:
        this.invite()
        event.preventDefault();
        break;
    }
    return;
  }

  resetPwd() {
    this.router.navigate(['/admin/reset']);
  }

  getCookie(key: string){
    return this._cookieService.get(key);
  }

    refer() {
        this.showAlert(AlertTypeEnum['success'], this.taxonomy+" referral URL is copied to the clipboard, Paste anywhere!");
        return;
    }

}
