<header class="doNotPrint"></header>
  <div class="doNotPrint" style="min-width:84%;width:100%; valign:middle;display:table;height:38px;padding-top:0px;margin:20px 0px 0px 0px; auto;border:0px;text-align:center;background-color:tomato;font-family:Helvetica,AppleGothic,sans-serif;color:#f5f5f5;">
    <div style="display:table-cell;width:20%; text-align:left;padding-left:15px;">
      <a style="font-size:1em;font-weight:600;" >Let's get in Touch</a>
    </div>
    <div style="display:table-cell;width:40%;">
      <a *ngIf="!loggedinUser" style="font-size:.9em;font-weight:500;" >For more info or a demo, drop us a message.</a>
      <a *ngIf="loggedinUser" style="font-size:.9em;font-weight:500;" >For more info or a feedback, drop us a message.</a>
    </div>
    <div style="display:table-cell;width:20%;text-align:right;padding-right:15px;">
      <a *ngIf="!loggedinUser" [routerLink]="['/contact']" class="siloqbutton" style="height:20px;border:0px;color:tomato;">Contact</a>
      <a *ngIf="loggedinUser" [routerLink]="['/admin/feedback']" class="siloqbutton" style="height:20px;border:0px;color:tomato;">Feedback</a>
    </div>
</div>

<div class="doNotPrint" style="display:table;width:100%; height:18px; margin-top:0px; valign:middle;padding-top:0px;">
  <div style="vertical-align: middle;padding:0px;margin:0px;width:40%;display:table-cell;">
    <a class="intLink"*ngIf="theUser" style="font-family: Arial, Helvetica, sans-serif;float:left;padding:0px 2px 0px 2px; margin:0px 0px 0px 3px;cursor:pointer;" title="{{curbemail(theUser)}}"><img src="assets/user.png" align="middle"  title="{{curbemail(theUser)}}"alt="{{curbemail(theUser)}}"style="margin-bottom:10px;width:18px; height:18px;" /></a>
    <input *ngIf="theGoldiUser && theGoldiUser !== 'null'" class="intLink" type="button" value="⭐️" valign="middle" style="background-color:#ffffff00;height:16px;width:16px;border:0px; padding:0px 0px 5px 0px;font-weight:300;font-size:.75em;"/>
  </div>
  <div  style="display:table-cell;font-family:Helvetica,AppleGothic,sans-serif; width:20%;text-align:center;color:mediumseagreen;font-size:2em;font-weight:600;vertical-align: middle;">
    <!--
    <div *ngIf="!theUser || (theUser && theUser === 'undefined')" style="margin:0 auto;text-align:center;">
      <img src="assets/{{taxonomy}}.png" align="middle" title="Not Logged In" alt="imockr™: Timeline Logo"style="margin-bottom:20px;width:24px; height:24px;" />
    </div>
    <div *ngIf="theUser" style="margin:0 auto;text-align:center;">
      <a *ngIf="userSVC.authUser.email && (!theGoldiUser || theGoldiUser === 'null')" style="b-moz-border-radius: 50px; border-radius: 50%;order-width:4px;padding:2px;text-decoration:none;background-color:whitesmoke;color:#383838;margin:0px 0px 0px 0px;font-weight:600;" title="{{userSVC.authUser.email}}" [routerLink]="['/admin/account']" class="siloqinputIn">&nbsp;{{theUser.toUpperCase().charAt(0)}}&nbsp;</a>&nbsp;
      <a *ngIf="userSVC.authUser.email && theGoldiUser && theGoldiUser !== 'null'" style="-moz-border-radius: 50px; border-radius: 50%;border-width:4px;padding:2px;text-decoration:none;background-color:white;border-color:orange;color:orange;margin:0px 0px 0px 0px;font-weight:600;" title="{{userSVC.authUser.email}}" [routerLink]="['/admin/account']" class="siloqinput">&nbsp;{{theUser.toUpperCase().charAt(0)}}&nbsp;</a>
    </div>
    -->
  </div>
  <div style="display:table-cell;font-family:Helvetica,AppleGothic,sans-serif;float:right;width:40%;margin:0px 0px 0px 20px;text-align:right;padding:0px 5px 0px 0px;font-size:1em;font-weight:200;">
    <select name="tags" id="tags" (change)="sort($event);"
            style="cursor:pointer;color:orangered;background-color:#ffffff00;height:18px;border:0px; padding:0px 0px 5px 0px;font-weight:300;font-size:.8em;"> <option value="email">User</option> <option value="hashtag">Topic</option> <option value="updatedate">Date</option> </select>
  </div>
</div>

    <div *ngIf="theUser" style="font-family:Helvetica,AppleGothic,sans-serif; width:60%;text-align:center;margin:0 auto;color:mediumseagreen;font-size:1.5em;font-weight:400;vertical-align: middle;text-align:center;">
      {{curbemail(theUser)}}
      <br *ngIf="theUser">
      <a class="siloqbutton" style="width:120px;background-color:orange;color:#383838;border:0px; border-radius: 30px; -moz-border-radius: 30px;height:20px;">Subscribe <b><i class='fa fa-bell-o' style="font-size:17px"></i></b></a>
    </div>
    <div *ngIf="!theUser" style="font-family:Helvetica,AppleGothic,sans-serif; width:60%;text-align:center;margin:0 auto;color:mediumseagreen;font-size:1.5em;font-weight:400;vertical-align: middle;text-align:center;">
      {{taxonomy}}
      <br *ngIf="!theUser">
      <a class="siloqbutton" style="width:120px;background-color:orange;color:#383838;border:0px; border-radius: 30px; -moz-border-radius: 30px;height:20px;">Subscribe<i class='far fa-bell' style="font-size:17px"></i></a>
    </div>
     <br>
    <br>
    <div class="block_container" style="font-family:Helvetica,AppleGothic,sans-serif; width:100%;margin:0 auto;color:mediumseagreen;font-size:1em;font-weight:200;vertical-align: middle;"><input name="siloqSearch" #siloqSearch  class="siloqinput" type="search" placeholder="🔍 Siloq&reg; Secure Local Exam Search"  [(ngModel)]='searchText' style="color: grey;padding:7px;height: 32px; width:42%;min-width:160px;margin: auto;"/></div>
    <div class="block_container" style="background-color:#ffffff00;border-bottom:1px solid gainsboro;padding:10px 0px 0px 0px;font-family:Century Gothic,CenturyGothic,AppleGothic,sans-serif; text-align: center; valign: top; font-size:1em;font-weight:200;"><a href="" ><img src="/assets/isloq.png" align="middle" style="margin-bottom:13px;width:19px; height:19px;" /></a> <span
                             style="color:grey;"></span> <a style="text-decoration:none;" href=""><span style="color:dodgerblue;">i</span><span
                             style="color:mediumseagreen;">s</span><span
                             style="color:orangered;">l</span><span
                             style="color:dodgerblue;">o</span><span
                             style="color:orange;">q</span><span
                             class="sup"
                             style="color:orangered;">&trade;</span><span style="color:dodgerblue;">&nbsp;Publish Instantly!</span>
                           </a>
</div>

<div id="textbox" style="width:90%; margin:0 auto; padding: 0px 4% 0px 4%;">
  <br class="doNotPrint">
  <div  *ngIf="topics && topics.length" class="col-md-12" style="margin:auto; font-family:Century Gothic,CenturyGothic,AppleGothic,sans-serif; padding-top:4px;padding-bottom:1px;background-color:white; color: navy; height:23px;text-align:center;width:85%;">
    <div style="display: inline-block;">
      <a *ngIf='currentPage>1' (click)="profileEx(1)" style="cursor:pointer;color:dodgerblue;"><<</a>&nbsp;&nbsp;<a *ngIf='currentPage>1' (click)="profileEx(currentPage-1)" style="cursor:pointer;color:dodgerblue;"><</a>&nbsp;&nbsp;Page {{currentPage}} of {{pageCount | number:0}}&nbsp;&nbsp;<a *ngIf='currentPage<pageCount' (click)="profileEx(currentPage+1)" style="color:dodgerblue;cursor:pointer;">></a>&nbsp;&nbsp;<a *ngIf='currentPage<pageCount' (click)="profileEx(pageCount)" style="color:dodgerblue;cursor:pointer;">>></a>
    </div>
  </div>
<div class="container" style="width:100%;color:#696969;border:0px solid #696969; border-color:gainsboro;margin-top:0px;">
	<div style="width:100%;overflow:hidden;">
		<table border = "0" cellpadding = "0" cellspacing = "0" style="font-family:arial,sans-serif; font-size:1em;font-weight:200;width:100%;">
			<tr class="block_container" style="color:grey;padding:40px;">
        <th style="width:100%;" valign="top">
          <div (click)="sortKey=sortKey.includes('-') ? 'hashtag' : '-hashtag'; setFirstPage();" style="cursor:pointer;font-weight:500;">
            <div *ngIf="!showAuto && topics && topics.length > 0" style="width:80%;overflow:hidden;padding-top:0px;" class="doNotPrint">
              <div *ngIf="!showAuto" style="padding:15px 0px 1px 10px;font-family:Century Gothic,CenturyGothic,AppleGothic,sans-serif; font-size:.75em;font-weight:400; color: navy; height:23px;text-align:left;" >
                <div *ngIf="topics && topics.length >= 64" style="display: inline-block;">
                  Page {{currentPage | number:'1.0-0'}} > {{totalCount |
                  number:'1.0-0'}},000,000 Activity(s)
                </div>
                <div *ngIf="topics && topics.length > 0 && topics.length < 64" style="display: inline-block;">
                  Page {{currentPage | number:'1.0-0'}} of {{totalCount |
                  number:'1.0-0'}} Activity(s)
                </div>
                <div  *ngIf="topics === undefined" style="display: inline-block; text-align:left;">
                  Working...
                </div>
                <div  *ngIf="topics && topics.length === 0" style="display: inline-block;">
                  No Activity(s)
                </div>
              </div>
            </div>
          </div>
        </th>
      </tr>


      <tr *ngFor="let topic of topics; let i = index" (click)="selectTopic(topic, i)" (mouseenter)="browseIndex=i;">
      <!--
      <tr *ngFor="let topic of selectedTopics; let i = index" (click)="selectTopic(topic, i)" (dblclick)="docTopic(topic)" (mouseenter)="browseIndex=i;">
      -->
        <td *ngIf="(!topic.edit || topic !== selectedTopic) && topic.show" style="margin-left:10%;" valign="top">

          <div class = "regular" [class.active]="i === selectedIndex" [class.browse]="i === browseIndex" style="padding:10px;">
            <div style="background-color:whitesmoke;width:100%;min-height:20px;height:auto;margin:0px;font-family:Helvetica,AppleGothic,sans-serif;padding:0px 0px 10px 0px;float:center;-moz-border-radius: 0px;border-radius: 0px;border-bottom:1px solid lightgrey;">

              <div style="float:left; text-align:left;width:80%;">
                <div  style="height:auto;color:navy;font-weight:500;font-size:.85em;" class="intLink" title="View Topic {{topic.hashTag}}" (click)='publicTopic(topic)' [innerHTML]="parseTitle(getParentTopic(topic)) | truncate : 64"></div>
              </div>

              <div style="text-align:right;min-width:60px;"> <input class="intLink" type="button" value="❐" valign="middle" (click)='publicTopic(topic)' title="View Topic {{topic.hashTag}}" style="vertical-align:middle;color:mediumseagreen;background-color:#ffffff00;height:16px;width:16px;border:0px; padding:0px 0px 16px 0px;font-weight:300;font-size:.75em;"/>
              </div>
            </div>

            <div style="vertical-align:top;margin:0px;text-align:left;padding:20px 5px 5px 5px;" >
              <div style="display:inline-block;width:100%;">
                <div (click)='publicTopic(topic)' style="display:table-cell;cursor:pointer;font-family:Century Gothic,CenturyGothic,AppleGothic,sans-serif; text-align: left; valign: top; font-size:.85em;font-weight:300;color:black" [innerHTML]="renderImage(topic.value) | truncate : 128 | safeHtml"> </div>
                <span class="doNotPrint" (click)='publicTopic(topic) 'style="cursor:pointer;text-align:center; border:0px; background-color:#ffffff00;height:16px;border:0px;padding:0px 0px 5px 0px;font-weight:200;font-size:.75em;color:navy;">{{topic.updateDate | date: 'M/d/yy, h:mm a'}}&nbsp;&nbsp;</span><span (click)="profile(topic);" title="View Profile {{curbemail(topic.email)}}" class="doNotPrint" style="cursor:pointer;background-color:#ffffff00;height:16px;border:0px;padding:0px 0px 5px 0px;font-weight:300;font-size:.75em;color:grey;">{{curbemail(topic.email)}}</span>
              </div>
            </div>
          </div>
        </td>
      </tr>

<!--
    <tr *ngFor="let topic of topics; let i = index" (click)="selectTopic(topic, i)" (dblclick)="docTopic(topic)" (mouseenter)="browseIndex=i;">

			<td *ngIf="(!topic.edit || topic !== selectedTopic) && topic.show" style="margin-left:10%;" valign="top">

           <div class = "regular" [class.active]="i === selectedIndex" [class.browse]="i === browseIndex" style="padding:0px;">
          <div style="background-color:whitesmoke;width:100%;margin:0px;height:20px;font-family:Helvetica,AppleGothic,sans-serif;padding:0px 0px 0px 0px;float:center;-moz-border-radius: 5px;border-radius: 5px">
            <div style="float:left; text-align:left;width:80%;">
              <span contenteditable="false" style="font-weight:500;font-size:1em;color:navy;" class="intLink" title="View Topic {{topic.hashTag}}" (click)='publicTopic(topic)' >{{getParentTopic(topic) | truncate : 128}}</span>
            </div>
            <div style="text-align:right;">
              <input class="intLink" type="button" value="❐" v align="middle" (click)='publicTopic(topic)' title="view {{topic.hashTag}}" style="vertical-align:middle;color:mediumseagreen;background-color:#ffffff00;height:16px;width:16px;border:0px; padding:0px 0px 16px 0px;font-weight:300;font-size:.75em;"/>
            </div>
          </div>

          <div style="vertical-align:top;margin:0px;text-align:left;padding:5px;" >
            <div style="display:inline-block;width:100%;">
              <div (click)='publicTopic(topic)' style="display:table-cell;cursor:pointer;font-family:Century Gothic,CenturyGothic,AppleGothic,sans-serif; text-align: left; valign: top; font-size:.85em;font-weight:400;color:black">{{topic.value | truncate : 256}}
              </div>
            <span class="doNotPrint" (click)='publicTopic(topic) 'style="cursor:pointer;text-align:center; border:0px; background-color:#ffffff00;height:16px;border:0px;padding:0px 0px 5px 0px;font-weight:300;font-size:.75em;color:navy;">{{topic.updateDate | date: 'M/d/yy, h:mm a'}}&nbsp;&nbsp;</span><span (click)="profile(topic);" title="View Profile {{curbemail(topic.email)}}" class="doNotPrint" style="cursor:pointer;background-color:#ffffff00;height:16px;border:0px;padding:0px 0px 5px 0px;font-weight:300;font-size:.75em;color:grey;">{{curbemail(topic.email)}}</span>

            </div>
          </div>

        </div>
      </td>
    </tr>
    -->
	</table>
	</div>

</div>
<br>
<div  *ngIf="topics && topics.length" class="col-md-12" style="margin:auto; font-family:Century Gothic,CenturyGothic,AppleGothic,sans-serif; padding-top:4px;padding-bottom:1px;background-color:white; color: navy; height:23px;text-align:center;width:90%;">
		<div style="display: inline-block;">
      <a *ngIf='currentPage>1' (click)="profileEx(1)" style="cursor:pointer;color:dodgerblue;"><<</a>&nbsp;&nbsp;<a *ngIf='currentPage>1' (click)="profileEx(currentPage-1)" style="cursor:pointer;color:dodgerblue;"><</a>&nbsp;&nbsp;Page {{currentPage}} of {{pageCount | number:0}}&nbsp;&nbsp;<a *ngIf='currentPage<pageCount' (click)="profileEx(currentPage+1)" style="color:dodgerblue;cursor:pointer;">></a>&nbsp;&nbsp;<a *ngIf='currentPage<pageCount' (click)="profileEx(pageCount)" style="color:dodgerblue;cursor:pointer;">>></a>
		</div>
	</div>
	 </div>

    <div class="block_container" style="background-color:#ffffff00;border-top:1px solid gainsboro;padding:10px 0px 0px 0px;font-family:Century Gothic,CenturyGothic,AppleGothic,sans-serif; text-align: center; valign: top; font-size:1em;font-weight:200;"><a href="http://sisloq.com" >
      <span style="color:dodgerblue;">Powered by</span> <img src="assets/isloq.png" align="middle" style="margin-bottom:13px;width:19px; height:19px;" /></a> <span style="color:grey;"></span> <a style="text-decoration:none;" href="http://isloq.com"><span style="color:dodgerblue;">i</span><span
      style="color:orangered;">s</span><span
      style="color:dodgerblue;">l</span><span
      style="color:orange;">o</span><span
      style="color:dodgerblue;">q</span><span
      class="sup"
      style="color:orangered;">&trade;</span>
    </a>
    </div>
<br>
<br>
<br>
<br>
<home-footer></home-footer>
