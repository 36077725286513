<dummy-header></dummy-header>
  <body style="height:100%;width:100%; margin:0px;">
  <div class="doNotPrint" style="background-color:whitesmoke;position:fixed;margin-top:47px;display:table;width:100%; height:38px; valign:middle;border:0px solid lightgrey;padding:0px 0px 0px 0px;">
    <div  style="text-align:center;margin:0 auto;color:mediumseagreen;padding:0px 5px 0px 12px;font-family:Helvetica,AppleGothic,sans-serif;font-size:1em; font-weight:600;width:90%;vertical-align: middle;">
      <a *ngIf="ready" class="fa fa-arrow-left intLink" title="Done with the cloning of '{{model.hashTag}}'" (click)="loqTopic(model.hashTag)" type="button" valign="middle" style="color:dodgerblue;;background-color:#ffffff00;font-size:1em;width:22px;height:22px;border:0px; padding-top:10px;font-weight:300;"></a>
      <a *ngIf="ready" class="fa fa-clone intLink" title="Clone original of '{{model.hashTag}}'" (click)='branchTopic()' type="button" valign="middle" style="color:dodgerblue;;background-color:#ffffff00;font-size:1em;width:22px;height:22px;border:0px; padding-top:10px;font-weight:300;"></a>
      <a *ngIf="ready" class="fa fa-copy intLink" title="Clone to me the '{{model.hashTag}}'" (click)="branchTopicToUser()" type="button" valign="middle" style="color:dodgerblue;;background-color:#ffffff00;font-size:1em;width:22px;height:22px;border:0px; padding-top:10px;font-weight:300;"></a>
    </div>
  </div>
  <div  class="doNotPrint" style="padding:100px 0px 10px 0px;font-family:Helvetica,AppleGothic,sans-serif; width:100%;text-align:center;color:mediumseagreen;font-size:1em;font-weight:500;vertical-align: middle;">Clone</div>
  <admin-alert></admin-alert>
  <ng-template id="modal" #modalTemplate>
    </ng-template>

  <div class="block_container" style="background-color:#ffffff00;border-bottom:1px solid gainsboro;padding:20px 0px 0px 0px;font-family:Century Gothic,CenturyGothic,AppleGothic,sans-serif; text-align: center; valign: top; font-size:1em;font-weight:200;"><a href="" ><img src="/assets/isloq.png" align="middle" style="margin-bottom:13px;width:19px; height:19px;" /></a> <span style="color:grey;"></span> <a style="text-decoration:none;" href=""><span
    style="color:dodgerblue;">i</span><span
    style="color:mediumseagreen;">s</span><span
    style="color:orangered;">l</span><span
    style="color:orange;">o</span><span
    style="color:dodgerblue;">q</span><span
    class="sup" style="color:orangered;">&trade;</span><span style="color:dodgerblue;">&nbsp;Publish Instantly!</span> </a>
  </div>
  <div id="loq" style="font-family:Helvetica,AppleGothic,sans-serif;width:92%;overflow:hidden;margin:4px 4% 40px 4%;padding-top:10px;z-index:-1px;">
    <h2 style="vertical-align: middle;padding:0px 0px 15px 5px;margin:0 auto;width:90%;text-align:center;">
      <div  style="font-family: Arial, Helvetica, sans-serif;padding:0px 0px 0px 2px; margin:0px 0px 0px 3px;" >{{model.hashTag}}</div>
    </h2>

 <div style="width:90%; margin:auto; padding-top: 10px;">
   <h4 style="font-family:Century Gothic,CenturyGothic,AppleGothic,sans-serif;">Clone(s)</h4>
  <input type="text" style="width:90%;" class="siloqinput" placeholder="Topic" [(ngModel)]='model.hashTag'/>&nbsp;
   <!--
   <button type="button" class="siloqbutton" (click)='branchTopic()' style="color:orangered;display:table-cell;">&nbsp;&nbsp;&nbsp;⏎&nbsp;&nbsp;&nbsp;</button>&nbsp;i
   <button type="button" class="siloqbutton" (click)='branchTopicToUser()' style="color:orangered;display:table-cell;">&nbsp;To Me&nbsp;</button>
        -->
        <br>
        <br>
	<div *ngIf="topic.topics && topic.topics.length <= 0"  class="siloqbox" style="text-align:left;font-family:arial,sans-serif; font-weight:100;margin:auto; width:90%;color:orangered; ">
    No Clones...
  </div>
	<div *ngIf="topic.topics && topic.topics.length > 0" class="container" style="width:90%;color:#696969;border:1px !important; border-color:gainsboro;">
      <div style="width:90%;height:30%;overflow:auto;margin:auto;text-align:center;color:grey;" class="siloqbox">
      <table border = "0" cellpadding = "0" cellspacing = "0" style="font-family:arial,sans-serif; font-size:.79em;margin:auto; border-color:gainsboro;padding:10px; width:90%;">
      <tr class="block_container">
        <th style="width:80%;" valign="top"> <div (click)="sortKey=sortKey.includes('-') ? 'hashtag' : '-hashtag'; setFirstPage();" style="padding:3px;">Clone</div> </th>
    </tr>
		<tr *ngFor="let branch of topic.topics; let i = index" style="background-color:#ffffff00;color:mediumseagreen;width:10px;text-align: right;" (click)="selectTopic(topic, i)" [class.active]="i === selectedIndex">
			<td valign="top" style="width:90%; background-color:#ffffff00;" *ngIf="(topic.hashTag !== jsunescape(branch))">
        <a contenteditable="false" class="intLink" style="background-color:#ffffff00;" title="view {{jsunescape(branch)}}" (click)='loqTopic(branch)' >{{jsunescape(branch) | truncate : 64}}</a>
       </td>
		</tr>
	</table>
	</div>
</div>
 </div>
    <br>
    <br>
    <div class="block_container" style="background-color:#ffffff00;border-top:1px solid gainsboro;padding:10px 0px 0px 0px;font-family:Century Gothic,CenturyGothic,AppleGothic,sans-serif; text-align: center; valign: top; font-size:1em;font-weight:200;"><a style="text-decoration:none;" href="http://siloq.com" >
      <span style="color:dodgerblue;">Powered by</span> <img src="assets/isloq.png" align="middle" style="margin-bottom:13px;width:19px; height:19px;" /></a> <span style="color:grey;"></span> <a style="text-decoration:none;" href="http://siloq.com"><span style="color:dodgerblue;">i</span><span
      style="color:mediumseagreen;">s</span><span
      style="color:dodgerblue;">l</span><span
      style="color:orange;">o</span><span
      style="color:dodgerblue;">q</span><span
      class="sup"
      style="color:orangered;">™</span>
    </a>
    </div>
 <br>
 <br>
<dummy-footer></dummy-footer>
