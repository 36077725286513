<header></header>
<div style="text-align:center; height:100%; width:100%; padding-top:70px;">
  <br>
<div class="row expanded align-center" style="text-align: center; margin: auto;min-width:300px;">
	<h2 class="block_container siloqlogofont" style="min-width:300px;">Join <a href="/"
                                                   target="_self"><span
      style="color:dodgerblue;">{{taxonomy}}</span><span
                                                   class="sup"
                                                   style="color:mediumseagreen;">&trade;</span></a> Today</h2>
</div>
<br>
  <alert></alert>
  <br>
<form>
<div class="row align-center">
   <div class="small-12  columns">
        <label>
          <input type="text" class="siloqinput" placeholder="Email"  id="email" [(ngModel)]="email" name="email" required #em="ngModel" style="min-width:300px;"/>
        </label>
 	  <div [hidden]="em.valid || em.pristine" style="margin:auto;text-align:left;width:300px;color:orangered;padding-top:5px;" class="siloqfont">
           Email is required
          </div>
    </div>
    <br>
    <br>
    <div class="small-12  columns" style="width:300px;text-align:left;
    margin:auto;">
	<div style="text-align:center;margin:auto;width:300px;" class="siloqfont">
		<button [disabled]="verifyEmail === true" (click)="verifyEmail = true; doSignUp();" type="button" class="siloqbutton">Verify Email</button>
 	  <div [hidden]="verifyEmail !== true" style="margin:auto;text-align:left;width:300px;color:mediumseagreen;padding-top:5px;" class="siloqfont">
           Verification email has been sent!
    </div>
 		<br>
 		<br>
    Please, check for an email verification link from <i style="color:mediumseagreen;">contact&#64;siloq.com</i> with login details. FYI: This email is not monitored.  <br>
                <br>
                <hr style="width:300px;">
                <br>
                <br>
		<div style="width:300px;text-align:left;margin: auto;"> By signing up, you agree to the <a [routerLink]="['/publish']" [queryParams]="{h:'isloq-terms-and-conditions'}" style="text-decoration:none;color:dodgerblue;">Terms of Service</a>  and <a [routerLink]="['/publish']" [queryParams]="{h:'isloq-privacy'}" style="text-decoration:none;color:dodgerblue;">Privacy Policy</a>, including <a [routerLink]="['/publish']" [queryParams]="{h:'isloq-cookie-use'}" style="text-decoration:none;color:dodgerblue;">Cookie Use</a>.
    </div>
		<br>
		<br>
		<br>
	</div>
    </div>
</div>
</form>
</div>
<footer></footer>

