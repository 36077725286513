import { Component, OnInit } from '@angular/core';
import {UserService} from '../../services/user.service';
import {ActivatedRoute, Router } from '@angular/router';
import { config } from '../../config';
import {RouterModule, RouterOutlet} from '@angular/router';
import { FormsModule } from '@angular/forms';
import {CookieService} from 'ng2-cookies';
import { CommonModule } from '@angular/common';

@Component({
  templateUrl: './admin-header.component.html',
  styleUrls: ['./admin-header.component.css'],
  selector: 'admin-header',
  imports: [CommonModule, RouterModule, RouterOutlet],
  standalone: true
})

export class AdminHeaderComponent implements OnInit {
  public identity = "";
  public taxonomy = "";
  public class = "";
  public subclass = "";
  public theUser = "";
  public theGoldiUser = "";

  constructor( private activatedRoute: ActivatedRoute, public userSVC: UserService, private router: Router, private _cookieService:CookieService){}

  getCookie(key: string){
    return this._cookieService.get(key);
  }

  ngOnInit(){
    this.userSVC.sessionVerify();
    this.theUser = this.getCookie("email");
    this.theGoldiUser = this.getCookie("gmt");
    this.class = config.class;
    this.subclass = config.subclass;
    this.identity = config.identity;
    this.taxonomy = config.taxonomy;
  }

  public newLoq() {
    this.router.navigate(['/admin/loq'],  { queryParams: { h : "newLoq", i : "newLoq", d : window.btoa(this.userSVC.authUser.email)}});
  }

  exams() {
      this.redirectTo("/admin/exams");
  }

    remocks() {
          this.router.navigate(['/admin/mocks'],  { queryParams: { t : "Remock", u : "mock.imoqr@siloq.com"}});
    }

    reexams() {
          this.router.navigate(['/admin/exams'],  { queryParams: { t : "Reexam", u : "scheduled.imoqr@siloq.com"}});
    }

  results() {
      this.router.navigate(['/admin/exams'],  { queryParams: { t : "Result", u : "result.imoqr@siloq.com"}});
  }

  redirectTo(uri: string) {
    this.router.navigateByUrl('/', { skipLocationChange: true }).then(() => {
      this.router.navigate([uri])});
  }

  goldimembers() {
    if(this.theGoldiUser && this.theGoldiUser !== 'null') {
      this.router.navigate(['/admin/mocks'],  { queryParams: { t : "GOLD", u : "gold.imoqr@siloq.com"}});
    } else {
      this.router.navigate(['/admin/goldimembers']);
    }
  }
}
