import { Injectable } from '@angular/core';
import { Response, Headers, RequestOptions } from '@angular/http';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import {Observable, throwError} from 'rxjs';
import {Topic} from '../admin/topic/model/topic';
import {Tag} from '../admin/tag/model/tag';
import { config } from '../config';
import {RouterModule} from '@angular/router';
import { SisHeaderComponent } from '../sisHeader/sis-header.component';
import { HomeFooterComponent } from '../homeFooter/home-footer.component';
import { FormsModule } from '@angular/forms';
import { map, pipe, of } from 'rxjs';
import { catchError } from 'rxjs/operators';

@Injectable()
export class TopicService {
constructor(private http: HttpClient) { }

  qrCode(body: Topic) : Observable<string> {
        return this.http.post<string>(config.topicsUrl + "topics/topic/qrcode", {"id": body.id, "email": body.email, "hashTag": body.hashTag, "taxonomy": body.taxonomy, "users": body.users, "topics": body.topics, "value": body.value}, { withCredentials: true })
  }

  getTopic(h: string, e: string) : Observable<Topic> {
         return this.http.post<Topic>(config.topicsUrl + "topics/topic/user", {"hashTag": h,  "email": e}, { withCredentials: true });
  }

  getTopicByUsers(h: string, e: string, u: string[]) : Observable<Topic> {
         return this.http.post<Topic>(config.topicsUrl + "topics/topic/users", {"hashTag": h,  "email": e, "users": u}, { withCredentials: true });
  }

  getPublicTopic(h: string, e: string) : Observable<Topic> {
         return this.http.post<Topic>(config.topicsUrl + "topics/topic/user/public", {"hashTag": h,  "email": e}, { withCredentials: true });
  }

  getTopicById(id: string) : Observable<Topic> {
         return this.http.post<Topic>(config.topicsUrl + "topics/topic", JSON.stringify(id), { withCredentials: true })
  }

  getTopicFinalized(id: string) : Observable<Topic> {
         return this.http.post<Topic>(config.topicsUrl + "topics/topic/finalized", JSON.stringify(id), { withCredentials: true })
  }

  publishTopic(h: string, e: string) : Observable<boolean> {
         return this.http.post<boolean>(config.topicsUrl + "topics/topic/publish", {"hashTag": h,  "email": e}, { withCredentials: true })
  }

  pressTopic(h: string, e: string) : Observable<boolean> {
         return this.http.post<boolean>(config.topicsUrl + "topics/topic/press", {"hashTag": h,  "email": e}, { withCredentials: true })
  }

  mockRequest(body: Topic) : Observable<boolean> {
      return this.http.post<boolean>(config.topicsUrl + "topics/topic/mock/request", {"id": body.id, "email": body.email, "hashTag": body.hashTag, "taxonomy": body.taxonomy, "users": body.users, "topics": body.topics, "value": body.value}, { withCredentials: true })
  }

  examRequest(body: Topic) : Observable<boolean> {
      return this.http.post<boolean>(config.topicsUrl + "topics/topic/exam/request", {"id": body.id, "email": body.email, "hashTag": body.hashTag, "taxonomy": body.taxonomy, "users": body.users, "topics": body.topics, "value": body.value}, { withCredentials: true })
  }

  examResponse(body: Topic) : Observable<boolean> {
    return this.http.post<boolean>(config.topicsUrl + "topics/topic/exam/response", {"id": body.id, "email": body.email, "hashTag": body.hashTag, "taxonomy": body.taxonomy, "users": body.users, "topics": body.topics, "value": body.value}, { withCredentials: true })
  }

  examFinalize(body: Topic) : Observable<boolean> {
    return this.http.post<boolean>(config.topicsUrl + "topics/topic/exam/finalize", {"id": body.id, "email": body.email, "hashTag": body.hashTag, "taxonomy": body.taxonomy, "users": body.users, "topics": body.topics, "value": body.value}, { withCredentials: true })
  }

  examResult(body: Topic) : Observable<boolean> {
     return this.http.post<boolean>(config.topicsUrl + "topics/topic/exam/result", {"id": body.id, "email": body.email, "hashTag": body.hashTag, "taxonomy": body.taxonomy, "users": body.users, "topics": body.topics, "value": body.value}, { withCredentials: true })
  }

  examScores(body: Topic) : Observable<Topic[]> {
    return this.http.post<Topic[]>(config.topicsUrl + "topics/topic/exam/scores", {"id": body.id, "email": body.email, "hashTag": body.hashTag, "taxonomy": body.taxonomy, "users": body.users, "topics": body.topics, "value": body.value}, { withCredentials: true })
  }

  examResults(body: Topic) : Observable<Topic[]> {
    return this.http.post<Topic[]>(config.topicsUrl + "topics/topic/exam/results", {"id": body.id, "email": body.email, "hashTag": body.hashTag, "taxonomy": body.taxonomy, "users": body.users, "topics": body.topics, "value": body.value}, { withCredentials: true })
  }

  examReexam(body: Topic) : Observable<Topic[]> {
      return this.http.post<Topic[]>(config.topicsUrl + "topics/topic/exam/reexam", {"id": body.id, "email": body.email, "hashTag": body.hashTag, "taxonomy": body.taxonomy, "users": body.users, "topics": body.topics, "value": body.value}, { withCredentials: true })
  }

  examMock(body: Topic) : Observable<Topic> {
      return this.http.post<Topic>(config.topicsUrl + "topics/topic/exam/mock", {"id": body.id, "email": body.email, "hashTag": body.hashTag, "taxonomy": body.taxonomy, "users": body.users, "topics": body.topics, "value": body.value}, { withCredentials: true })
  }

     getTopics() : Observable<Topic[]> {
            return this.http.get<Topic[]>(config.topicsUrl, { withCredentials: true })
            .pipe(catchError((error:any) => throwError(error.json().error || 'Server error')));
     }

     searchTopics(s: string, k: string, d: string, n: string) : Observable<Topic[]> {
         return this.http.get<Topic[]>(config.topicsUrl + "SearchTopic/?s=" + s + "&k=" + k + "&d=" + d + "&n=" + n)
            .pipe(catchError((error:any) => throwError(error.json().error || 'Server error')));
     }

     //public
     publicSearchTopics(s: string, k: string, n: string) : Observable<Topic[]> {
                  return this.http.get<Topic[]>(config.topicsUrl + "PublicSearchTopic/?s=" + s + "&k=" + k + "&n=" + n)
            .pipe(catchError((error:any) => throwError(error.json().error || 'Server error')));
     }

    pressSearchTopics(s: string, k: string, n: string) : Observable<Topic[]> {
                  return this.http.get<Topic[]>(config.topicsUrl + "PressSearchTopic/?s=" + s + "&k=" + k + "&n=" + n)
            .pipe(catchError((error:any) => throwError(error.json().error || 'Server error')));
     }

    examSearchTopics(s: string, k: string, e: string, d: string, n: string) : Observable<Topic[]> {
                  return this.http.get<Topic[]>(config.topicsUrl + "ExamSearchTopic/?s=" + s + "&k=" + k + "&e=" + e + "&d=" + d + "&n=" + n)
            .pipe(catchError((error:any) => throwError(error.json().error || 'Server error')));
     }

    //Public
    mockSearchTopics(s: string, k: string, n: string) : Observable<Topic[]> {
                  return this.http.get<Topic[]>(config.topicsUrl + "MockSearchTopic/?s=" + s + "&k=" + k + "&n=" + n)
            //.pipe(catchError((error:any) => throwError(error.json().error || 'Server error')));
     }

     filterTopics(h: string, s: string, v: string, k: string, d: string) : Observable<Topic[]> {
                  return this.http.get<Topic[]>(config.topicsUrl + "FilterTopic/?h=" + h + "&s=" + s + "&v=" + v + "&k=" + k + "&d=" + d)
            .pipe(catchError((error:any) => throwError(error.json().error || 'Server error')));
     }

    addTopic (body: Topic): Observable<Object> {
                       return this.http.post<Object>(config.topicsUrl + "topics/", {"email": body.email, "hashTag": body.hashTag, "taxonomy": body.taxonomy, "users": body.users, "topics": body.topics, "value": body.value}, { withCredentials: true })
    }

    mergeTopics (body: Topic): Observable<Object> {
                       return this.http.post<Object>(config.topicsUrl + "topics/merge", {"id": body.id, "email": body.email, "hashTag": body.hashTag, "taxonomy": body.taxonomy, "users": body.users, "topics": body.topics, "value": body.value}, { withCredentials: true })
    }

    updateTopic (body: Topic): Observable<Topic> {
        return this.http.put<Topic>(config.topicsUrl + "topics/" + body.id, {"id": body.id, "email": body.email, "hashTag": body.hashTag, "taxonomy": body.taxonomy, "users": body.users, "topics": body.topics, "value": body.value}, { withCredentials: true })
    }

    cloneTopic (body: Topic): Observable<Object> {
                       return this.http.put<Object>(config.topicsUrl + "/topics/clone/topic/from/to/new", JSON.stringify(body), { withCredentials: true })
    }

    cloneTopicToUser (body: Topic): Observable<Object> {
                    return this.http.put<Object>(config.topicsUrl + "/topics/clone/topic/to/user", JSON.stringify(body), { withCredentials: true })
    }

    renameTopic (body: Topic): Observable<Object> {
                  return this.http.put<Object>(config.topicsUrl + "/topics/rename/topic/from/to", {"id": body.id, "email": body.email, "hashTag": body.hashTag, "taxonomy": body.taxonomy, "users": body.users, "topics": body.topics, "value": body.value}, { withCredentials: true })
    }

    updateTopicValue (body: Topic): Observable<Object> {
               return this.http.put<Object>(config.topicsUrl + "topics/" + body.id + "/value/"+body.value, { withCredentials: true })
    }

    archiveTopic (body: Topic): Observable<Object> {
      return this.http.put<Object>(config.topicsUrl + "topics/archive/disable/" + body.id, {"id": body.id, "email": body.email, "hashTag": body.hashTag, "taxonomy": body.taxonomy, "users": body.users, "topics": body.topics, "value": body.value}, { withCredentials: true })
      .pipe(catchError((error:any) => throwError(error || 'Server error'))); //...errors if any
    }

   deleteTopic (id: String): Observable<Object> {
            return this.http.delete<Object>(config.topicsUrl+"topics/" + id, { withCredentials: true }) // ...using delete request
                         .pipe(catchError((error:any) => throwError(error || 'Server error'))); //...errors if any
    }

  getTopicTags(body: Topic) : Observable<Tag[]> {
    return this.http.post<Tag[]>(config.topicsUrl + "/topics/topic/tags", {"id": body.id}, { withCredentials: true })
    .pipe(catchError((error:any) => throwError(error.json().error || 'Server error')));
  }
}
