import {OnInit, Component } from '@angular/core';
import {UserService} from '../../services/user.service';
import {Login} from '../../services/user.service';
import { Router, ActivatedRoute, Params } from '@angular/router';
import {CookieService} from 'ng2-cookies';
import { AdminAlertComponent } from '../adminAlert/admin-alert.component';
import { AdminAlertService } from '../adminAlert/services/admin-alert.service';
import { AlertTypeEnum } from '../adminAlert/types/alertType.enum';
import { config } from '../../config';
import {RouterModule} from '@angular/router';
import { DummyHeaderComponent } from '../dummyHeader/dummy-header.component';
import { DummyFooterComponent } from '../dummyFooter/dummy-footer.component';
import { FormsModule } from '@angular/forms';
import { CommonModule } from '@angular/common';

@Component({
  host: {
    '(document:keydown)': 'keyDown($event)',
  },

  templateUrl: './reset.component.html',
  styleUrls: ['./reset.component.css'],
  imports: [AdminAlertComponent, FormsModule, RouterModule, DummyFooterComponent, DummyHeaderComponent, CommonModule],
  standalone: true
})

export class ResetComponent implements OnInit {
  public passwd= "";
  public password2= "";
  public passwordFail: boolean = false;
  public taxonomy = "";
  public class = "";
  public subclass = "";

  constructor( private alertService: AdminAlertService, public userSVC: UserService, private router: Router, private activatedRoute: ActivatedRoute, private loginInfo: Login, private _cookieService:CookieService){
    loginInfo = new Login();
  }

  ngOnInit() {
    this.taxonomy = config.taxonomy;
    this.class = config.class;
    this.subclass = config.subclass;
  }

  getCookie(key: string){
    return this._cookieService.get(key);
  }

showAlert(type: AlertTypeEnum, msg: string) {
    this.alertService.setAlert({
      type,
      text: msg,
    });
  }

  doreset(){
    if (!this.passwd || !this.password2)
    {
      this.passwordFail = true;
      this.showAlert(AlertTypeEnum['warning'], "Either of the password should not be empty");
      return;
    }

    if (!this.password2)
    {
      this.passwordFail = true;
      this.showAlert(AlertTypeEnum['warning'], "Confirm the password");
      return;
    }

    if (this.passwd.length < 6 || this.password2.length < 6)
    {
      this.passwordFail = true;
      this.showAlert(AlertTypeEnum['warning'], "The length of the passwd should be at least 6 chars");
      return;
    }

    if (this.passwd !== this.password2) {
      this.passwordFail = true;
      this.showAlert(AlertTypeEnum['warning'], "The passwords does not match");
      return;
    }

    this.passwordFail = false;
    this.loginInfo.email = btoa(this.userSVC.authUser.email);
    this.loginInfo.sessionId = this.getCookie("gwt");
    this.loginInfo.goldimembersSessionId = this.getCookie("gmt");
    this.loginInfo.passwd = btoa(this.passwd);
    this.reset();
  }

  reset() {
    this.userSVC.reset(this.loginInfo).subscribe(
      (data: any) => {
       this.showAlert(AlertTypeEnum['warning'], "All good, your password has been reset!");
       window.setTimeout(() => {
           this.router.navigate(['/login']);
       }, 3000);
      },
       (err: any) => {
        console.log(err);
      });
  }

 public keyDown(event: any) {
    switch (event.keyCode) {
      case 13:
        this.reset()
        event.preventDefault();
        break;
    }
    return;
  }

  done() {
    this.router.navigate(['/admin/account']);
  }
}
