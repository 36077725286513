import { Component, ElementRef, EventEmitter, Input, Output, } from '@angular/core';
import { config } from '../config';
import {RouterModule} from '@angular/router';
import { FormsModule } from '@angular/forms';
import {HostListener} from '@angular/core';

@HostListener('window:keydown', ['$event'])
@Component({
  host: {
    '(document:keydown)': 'keyDown($event)',
  },
  selector: 'modal',
  templateUrl: './modal.component.html',
  styleUrls: ['./modal.component.css'],
  imports: [FormsModule, RouterModule],
  standalone: true
})

export class ModalComponent {
  @Input() size? = 'md';
  @Input() title? = 'Modal title';
  @Input() message? = 'Modal message';

  @Output() closeEvent = new EventEmitter();
  @Output() submitEvent = new EventEmitter();

  public taxonomy = "";
  public class = "";
  public subclass = "";

  constructor(private elementRef: ElementRef) {}

  ngOnInit(): void {
    this.taxonomy = config.taxonomy;
    this.class = config.class;
    this.subclass = config.subclass;
  }

  close(): void {
    this.elementRef.nativeElement.remove();
    this.closeEvent.emit();
  }

  submit(): void {
    this.elementRef.nativeElement.remove();
    this.submitEvent.emit();
  }

  keyDown(event: any) {
     switch (event.keyCode) {
       case 27:
         this.close();
         break;
     }
     return;
   }
}
