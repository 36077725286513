  <div style="height:38px;width:100%;" >
  <div style="left:0; top:0; position:fixed;width:100%;background-color:#ffffff;">
     <div style="height:6px;width:100%;background-color:ffffff00;" (mouseenter)="menu=false;">
  </div>

    <div style="font-family: 'Segoe UI', Frutiger, 'Frutiger Linotype', 'Dejavu Sans', 'Helvetica Neue', Arial, sans-serif; display:table-cell;font-size:1em;font-weight:600px;height:38px; width:100%; padding:0px 5px 0px 5px; display:table;text-align:left;margin:0 auto;" >
      <div (mouseenter)="menu=false;"style="padding-left:10px;flow:left;text-align:left;display:table-cell;width:30%;">
        <a *ngIf="!userSVC.authUser.email" [routerLink]="['/home']" ><img src="assets/admin-{{taxonomy}}-dark.png" align="middle" title="HOME" alt="HOME" style="cursor:pointer;margin-bottom:11px;width:31px; height:31px;" /></a>
        <a *ngIf="userSVC.authUser.email" [routerLink]="['/home']" ><img src="assets/admin-{{taxonomy}}.png" align="middle" title="HOME" alt="HOME"style="cursor:pointer;margin-bottom:11px;width:31px; height:31;" /></a>
        <a *ngIf="!userSVC.authUser.email" [routerLink]="['/publish']" [queryParams]="{h:'siloq-apps'}"><img src="assets/siloq-apps-dark.png" align="middle" title="Siloq® Apps" alt="Siloq® Apps"  style="cursor:pointer;margin-bottom:10px;width:30px; height:30px;" /></a>
        <a *ngIf="userSVC.authUser.email" [routerLink]="['/publish']" [queryParams]="{h:'siloq-apps'}"><img src="assets/siloq-apps.png" align="middle" title="Siloq® Apps" alt="Siloq® Apps"  style="cursor:pointer;margin-bottom:10px;width:30px; height:30px;" /></a>
      </div>

      <div *ngIf="taxonomy===identity" (click)='toggleMenu($event);' style="display:table-cell;text-align:center;padding:10px 1% 0px 0px;width:38%;font-size:1em;font-weight:700px;margin:0 auto;" (mouseenter)="menu=true;resetTimer();$event.stopPropagation();">

        <!-- glean -->
        <a (mouseenter)="menu=false;" *ngIf="userSVC.authUser.email" routerLink="/admin/topic"><img src="assets/isloq.png" align="middle" title="Loq ACTs" alt="Loq ACTs"  style="cursor:pointer;margin-bottom:10px;width:30px; height:30px;" /></a>
        <a (mouseenter)="menu=false;" *ngIf="!userSVC.authUser.email" routerLink="/profile"><img src="assets/isloq-dark.png" align="middle" title="Loq ACTs" alt="Loq ACTs"  style="cursor:pointer;margin-bottom:10px;width:30px; height:30px;" /></a>
        <!-- transform -->
        <a (mouseenter)="menu=false;" *ngIf="userSVC.authUser.email" routerLink="/admin/exams"><img src="assets/sisloq-logo.png" align="middle" title="Conduct EXAMs" alt="Conduct EXAMs"  style="cursor:pointer;margin-bottom:10px;width:28px; height:28px;" /></a>
        <a (mouseenter)="menu=false;" *ngIf="!userSVC.authUser.email" routerLink="/admin/exams"><img src="assets/sisloq-logo-dark.png" align="middle" title="Conduct EXAMs" alt="Conduct EXAMs"  style="cursor:pointer;margin-bottom:10px;width:28px; height:28px;" /></a>
        <!-- retrieve -->
        <a (mouseenter)="menu=false;" *ngIf="userSVC.authUser.email" routerLink="/admin/mocks"><img src="assets/siloq-logo.png" align="middle" title="Practice MOCKs" alt="Practice MOCKs"  style="cursor:pointer;margin-bottom:10px;width:28px; height:28px;" /></a>
        <a (mouseenter)="menu=false;" *ngIf="!userSVC.authUser.email" routerLink="/admin/mocks"><img src="assets/siloq-logo-dark.png" align="middle" title="Practice MOCKs" alt="Practice MOCKs"  style="cursor:pointer;margin-bottom:10px;width:28px; height:28px;" /></a>

      </div>

      <div *ngIf="taxonomy==='iexamr'" (click)='toggleMenu($event);' style="display:table-cell;text-align:center;padding-top:10px;width:38%;font-size:1em;font-weight:700px;margin:0 auto;" (mouseenter)="menu=true;resetTimer();$event.stopPropagation();">
        <!-- exam published - for group of students - specific-time-->
        <a (mouseenter)="menu=false;" *ngIf="subclass!=='admin' && userSVC.authUser.email" href="/admin/exams?t=Request&u=request.imoqr@siloq.com"><img style="text-decoration:none;width:30px; height:30px;" title="{{taxonomy}} Recent exam requests" alt="{{taxonomy}} Recent exam requests" align="middle" src="assets/iexamr.png"  style="cursor:pointer;margin-bottom:10px;width:30px; height:30px;"/></a>
        <a (mouseenter)="menu=false;" *ngIf="subclass!=='admin' && !userSVC.authUser.email" href="/admin/exams?t=Request&u=request.imoqr@siloq.com"><img style="text-decoration:none;width:30px; height:30px;" title="{{taxonomy}} Recent exam requests" alt="{{taxonomy}} Recent exam requests" align="middle" src="assets/iexamr-dark.png"  style="cursor:pointer;margin-bottom:10px;width:30px; height:30px;"/></a>

        <!-- publish exam - teacher -->
        <a (mouseenter)="menu=false;" *ngIf="subclass==='admin' && userSVC.authUser.email" href="/admin/exams"><img style="text-decoration:none;width:30px; height:30px;" title="{{taxonomy}} Recent exams" alt="{{taxonomy}} Recent exams" align="middle" src="assets/iexamr.png"  style="cursor:pointer;margin-bottom:10px;width:30px; height:30px;"/></a>
        <a (mouseenter)="menu=false;" *ngIf="subclass==='admin' && !userSVC.authUser.email" href="/admin/exams"><img style="text-decoration:none;width:30px; height:30px;" title="{{taxonomy}} Recent exams" alt="{{taxonomy}} Recent exams" align="middle" src="assets/iexamr-dark.png"  style="cursor:pointer;margin-bottom:10px;width:30px; height:30px;"/></a>

        <!-- reexam the published exams - exam -->
        <a (mouseenter)="menu=false;" *ngIf="subclass==='admin' && userSVC.authUser.email" (click)="reexams();" ><img style="text-decoration:none;width:30px; height:30px;" title="{{taxonomy}} Reexam" alt="{{taxonomy}} Reexam" align="middle" src="assets/reexam.png"  style="cursor:pointer;margin-bottom:10px;width:30px; height:30px;"/></a>
        <a (mouseenter)="menu=false;" *ngIf="subclass==='admin' && !userSVC.authUser.email" (click)="reexams();"><img style="text-decoration:none;width:30px; height:30px;" title="{{taxonomy}} Reexam" alt="{{taxonomy}} Reexam" align="middle" src="assets/reexam-dark.png"  style="cursor:pointer;margin-bottom:10px;width:30px; height:30px;"/></a>

        <!-- result published - of each student who took the exam - for teacher -->
        <a (mouseenter)="menu=false;" *ngIf="userSVC.authUser.email" (click)="results();"  ><img style="text-decoration:none;width:30px; height:30px;" title="{{taxonomy}} Recent Results" alt="{{taxonomy}} Recent Results" align="middle" src="assets/result.png"  style="cursor:pointer;margin-bottom:10px;width:30px; height:30px;"/></a>
        <a (mouseenter)="menu=false;" *ngIf="!userSVC.authUser.email" (click)="results();" ><img style="text-decoration:none;width:30px; height:30px;" title="{{taxonomy}} Recent Results" alt="{{taxonomy}} Recent Results" align="middle" src="assets/result-dark.png"  style="cursor:pointer;margin-bottom:10px;width:30px; height:30px;"/></a>
      </div>

      <div (mouseenter)="menu=false;"style="display:table-cell;width:30%;flow:right;text-align:right;padding-right:20px;">
        <!-- mocks - for individual student/teacher - Goldimembers Only -->
        <a style="font-size:.8em;cursor:pointer;border-width:4px;background-color:whitesmoke;color:#383838;padding:3px 4px 3px 4px;text-decoration:none;margin:0px 0px 0px 0px;" *ngIf="userSVC.authUser.email" (click)="goldimembers();" class="siloqinput" title="GOLDIMOCKS">⭐️</a>
        <a style="font-size:.8em;cursor:pointer;border-width:4px;background-color:whitesmoke;color:#383838;padding:3px 4px 3px 4px;text-decoration:none;margin:0px 0px 0px 0px;" *ngIf="!userSVC.authUser.email" href="/goldimembers" class="siloqinput" title="GOLDIMOCKS">⭐️</a>
        <a *ngIf="userSVC.authUser !== undefined && userSVC.authUser.email && theGoldiUser && theGoldiUser !== 'null'" style="border-width:4px;padding:1px;text-decoration:none;background-color:white;border-color:orange;color:orange;margin:0px 0px 0px 0px;font-weight:600" title="{{userSVC.authUser.email}}" [routerLink]="['/admin/account']" class="siloqinput">&nbsp;{{userSVC.authUser.email.toUpperCase().charAt(0)}}&nbsp;</a>
        <a *ngIf="userSVC.authUser !== undefined && userSVC.authUser.email && (!theGoldiUser || theGoldiUser === 'null')" style="border-width:4px;padding:2px;text-decoration:none;margin:0px 0px 0px 0px;font-weight:600" title="{{userSVC.authUser.email}}" [routerLink]="['/admin/account']" class="siloqinput">&nbsp;{{userSVC.authUser.email.toUpperCase().charAt(0)}}&nbsp;</a>
        <a *ngIf="!userSVC.authUser.email" [routerLink]="['/login']" style="text-decoration:none;"><img src="assets/gold-dark.png" align="middle" title="{{taxonomy}}: Sign In" alt="{{taxonomy}}: Sign In"style="cursor:pointer;margin:0px 0px 10px 0px;width:30px; height:30px;" /></a>
      </div>
    </div>

  <div id="headerMenu" *ngIf="menu" class="clsa" style="height:48; max-height:44px;padding-top:20px;display:table;border-width:0px;border:1px solid lightgray; border-top:0px; text-align:left; margin:0 auto;;width:80%" >
     <div style="border-width:1px;margin:auto;width:100%;display:table-row;" >
       <div style="margin: auto; font-family: 'Segoe UI', Frutiger, 'Frutiger Linotype', 'Dejavu Sans', 'Helvetica Neue', Arial, sans-serif; width:60%;display:table-cell;font-size:.9em;font-weight:600px;">
          <div style="display:inline; text-align:left;">
            <ul class="menu" style="border:0px;overflow:auto;"
                       (click)='toggleMenu($event);'>
			  			  <li>
                  <a href="https://chromewebstore.google.com/detail/{{taxonomy}}/{{chromeExtension}}" style="text-decoration:none;margin-bottom:7px;font-size:1em;padding:5px;border:1px solid mediumseagreen;font-weight:500;color:dodgerblue" class="siloqfont" target="_blank">Download {{taxonomy}} Extn</a>
			  </li>
        <li>
          <a [routerLink]="['/publish']" [queryParams]="{h:'isloq™ Chrome Extension'}" style="text-decoration:none;color:dodgerblue;">About <span
            style="color:dodgerblue;">{{taxonomy}}</span><span
            class="sup"
            style="color:mediumseagreen;">&trade;</span> Extension</a>
			  </li>
			  <li>
          <a [routerLink]="['/publish']" [queryParams]="{h:'isloq-help'}" style="text-decoration:none;color:dodgerblue;">Help</a>
			  </li>
			  <li *ngIf="!userSVC.authUser.email">
          <a title="Please contact us" style="text-decoration:none;color:dodgerblue;" class="intLink" [routerLink]="['/contact']">Contact</a>
			  </li>
        <li *ngIf="userSVC.authUser.email">
          <a title="Please tell us what you want more better"
             style="text-decoration:none;color:dodgerblue;" class="intLink" [routerLink]="['/admin/feedback']">Feedback</a>
        </li>
        <li>
          <a [routerLink]="['']" style="text-decoration:none;color: #383838;">Home</a>
        </li>

			  </ul>

		       </div>
      </div>
     </div>
      <div style="height:12px;width:100%;background-color:ffffff00;" (mouseenter)="menu=false;">
  </div>
     </div>
      </div>
 </div>

