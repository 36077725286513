import { Component, OnInit, TemplateRef, ViewChild } from '@angular/core';
import {UserService} from '../../services/user.service';
import {Login} from '../../services/user.service';
import {ActivatedRoute, Router } from '@angular/router';
import {CookieService} from 'ng2-cookies';
import { ModalService } from '../../modal/services/modal.service';
import { ModalComponent } from '../../modal/modal.component';
import { config } from '../../config';
import {RouterModule} from '@angular/router';
import { AdminHeaderComponent } from '../adminHeader/admin-header.component';
import { AdminFooterComponent } from '../adminFooter/admin-footer.component';
import { FormsModule } from '@angular/forms';
import { CommonModule } from '@angular/common';

@Component({
host: {
      '(document:keydown)': 'keyDown($event)',
      },

  templateUrl: './account.component.html',
  styleUrls: ['./account.component.css'],
  imports: [RouterModule, AdminFooterComponent, AdminHeaderComponent, CommonModule, ModalComponent],
  standalone: true
})

export class AccountComponent implements OnInit {
 public theUser = "";
 public theGoldiUser = "";
 femail= "";
 searchText= "";
 public taxonomy = "";
 public class = "";
 public subclass = "";
 public memberType = "";

 @ViewChild('modalTemplate', {read: TemplateRef}) modalTemplate: TemplateRef<any>;

  constructor(private activatedRoute: ActivatedRoute, public userSVC: UserService, private router: Router, private loginInfo: Login, private _cookieService:CookieService, private modalService: ModalService){}

  setCookie(key: string, value: string){
    return this._cookieService.set(key, value);
  }

  getCookie(key: string){
    return this._cookieService.get(key);
  }

  openModal(modalTemplate: TemplateRef<any>) {
    this.modalService
      .open(modalTemplate, { size: 'sm', title: 'Sign Out', message: "Do you want to Sign Out?" })
      .subscribe((action) => {
          let gwt = this.getCookie("gwt");
          if(gwt) {
            this.sysLogout(gwt, () => {
              this.router.navigate(['/logout']);
            });
          }
      });
  }

  sysLogout(gwt: string, callback: () => any) {
    this.userSVC.logout(gwt)
      .subscribe(() => {
        this.setCookie("gwt", "");
        this.setCookie("gmt", "");
        this.setCookie("uid", "");
        this.setCookie("email", "");
        callback();
      })
  }

  ngOnInit() {
    this.userSVC.sessionVerify();
    this.theUser = this.getCookie("email");
    this.theGoldiUser = this.getCookie("gmt");
    this.taxonomy = config.taxonomy;
    this.class = config.class;
    this.subclass = config.subclass;
    this.memberType = config.memberType;

     this.activatedRoute.queryParams.subscribe(params => {
        this.searchText = params['q'] || "";
      });
  }

  validateEmail(email: any) {
     const re = /^(([^<>()[\]\\.,;:\s@\"]+(\.[^<>()[\]\\.,;:\s@\"]+)*)|(\".+\"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
     return re.test(email);
  }

  invite() {
      this.router.navigate(['/admin/invite']);
    }

  tiny() {
      this.router.navigate(['/admin/tiny']);
    }

  qrcode() {
      this.router.navigate(['/admin/qrcode']);
    }

  done() {
      this.router.navigate(['/admin/topic']);
    }

 public keyDown(event: any) {
    switch (event.keyCode) {
      case 27:
        document.getElementById("modal").remove();
        break;
      case 13:
        this.profile()
        break;
    }
    return;
  }

  resetPwd() {
      this.router.navigate(['/admin/reset']);
    }

  logout(){
    this.openModal(this.modalTemplate);
  }

  curbemail(email: string): string {
    if(!email) {
      return email;
    }

    var index = email.indexOf("@");
    if(index > 0) {
      return email.substring(0, index);
    }

    return email;
  }

  profile() {
    this.router.navigate(['profile'],  { queryParams: {d : window.btoa(this.theUser)}});
    return;
  }

  topics() {
    this.router.navigate(['admin/topic']);
    return;
  }

  tel() {
    this.router.navigate(['/admin/tel']);
  }

  upload() {
    this.router.navigate(['/admin/upload']);
  }

  career() {
    this.router.navigate(['/admin/career']);
  }
  results() {
      this.router.navigate(['/admin/exams'],  { queryParams: { t : "Result", u : "result.imoqr@siloq.com"}});
    }
 }
